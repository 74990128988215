
@import '~@angular/material/theming';

@mixin alert-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $border-radius: 2px;

  .alert {
     border-radius: $border-radius;
     padding: 10px;
     display: inline-flex;
     flex-direction: row;
     align-items: center;
     align-content: center;
     justify-content: flex-start;

     &.mat-primary {
       background-color: mat-color($primary, lighter);
       color: mat-color($warn, lighter-contrast);
     }

     &.mat-accent {
       background-color: mat-color($accent, lighter);
       color: mat-color($warn, lighter-contrast);
     }

     &.mat-warn {
       background-color: mat-color($warn, lighter);
       color: mat-color($warn, lighter-contrast);
     }
  }

  .alert-icon {
    margin-right: 5px;
  }


}
