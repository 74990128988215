
@import '~@angular/material/theming';

@mixin sidebar-section-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $border-radius: 2px;

  .side-bar-section {
    display: flex;
    flex-direction: row;
  }

  .side-bar-section-header-title {
    color: mat-color($foreground, text);
    margin: 0 0 5px 0 !important;
  }

  .side-bar-section-header-subtitle {
    color: mat-color($foreground, secondary-text);
    margin: 0 !important;
  }

  .side-bar-section-mobile {
    flex-direction: column;

    > .side-bar-section-content {
      padding: 10px;
      margin-left: 0;
    }
  }

  .side-bar-section-header {
    min-width: 300px;
    padding-left: 5px;
  }

  .side-bar-section-content {
    flex-grow: 1;
    margin-left: 30px;
  }
}
