
.gp-link {
  color: black;
}

.text-sm {
  font-size: 0.85em;
}

.text-underline {
  text-decoration: underline;
}

.text-hover-pointer {
  cursor: pointer;
}

.unstyled-link {
  color: black;
}

.hover-underline {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.light-bold {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.word-wrap {
  word-break: break-all;
  hyphens: auto;
}
