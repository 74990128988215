
.organization-card {
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;
}

.add-organization-card {
  @extend .organization-card;
  background-color: darken(#FFF, 2);
}
