
@import "mixins";

.gp-pipeline {
  display: flex;
  flex-direction: row;
  @include flex-align-start-center()
}

.gp-pipeline-mobile {
  display: block;

  > .gp-pipeline-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 35px;
    margin-bottom: 8px;
  }

  & .gp-pipeline-mobile-title {
    display: block;
  }
}

.gp-pipeline-mobile-title {
  display: none;
  margin: auto auto auto 0 !important;
}

.gp-pipeline-item {

}

.gp-pipeline-number-item {
  min-width: 100px;
}

.gp-pipeline-number {
  @include flex-row();
  @include flex-layout-gap-row(3px);
}

.gp-pipeline-target-packages {
  @include flex-row();
}

.gp-pipeline-status-counter {
  @include flex-row();
  @include flex-align-start-center();
  @include flex-layout-gap-row(2px);
}

.gp-pipeline-stats {
  min-width: 200px;
}
