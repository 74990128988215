
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-align-start-center {
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

@mixin flex-layout-gap-row($size) {
  > * {
    margin-left: $size;
    margin-right: $size;
  }
}

@mixin flex-layout-gap-column($size) {
  > * {
    margin-bottom: $size;
    margin-top: $size;
  }
}
