
@import '~@angular/material/theming';

@mixin card-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $border-radius: 2px;

  .gray-mat-card {
    display: inline-block;
  }

  .gray-mat-card-header {
    background-color: mat-color($background, disabled-button-toggle);
    color: mat-color($foreground, disabled-text);

    padding: 5px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    & > h1,h2,h3,h4,h5,h6 {
      margin-bottom: 0 !important;
    }
  }

  .gray-mat-card-content {
    background-color: mat-color($background, card);
    padding: 5px;

    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
