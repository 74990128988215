
.circle-avatar {
  border-radius: 50%;
  width: 22px;
  height: auto;
  vertical-align: middle;
}

.gitlab-svg {
  svg {
    width: 80%;
    height: auto;
    margin-bottom: 3px;
    margin-left: -3px;
  }
}
