
.portal-container {
  height: 100%;
  width: 100%;
}

.portal-content-desktop {
  padding: 10px;
}

.portal-sidebar-nav-container {
  width: 100%;
  height: 100%;
}

.breadcrumbs-container {
  font-size: 0.75rem;
}

.breadcrumbs-icon-container {
  width: 14px
}

.breadcrumbs-icon {
  transform: scale(0.75);
  width: 14px
}
