
@import "mixins";

.gp-build {
  @include flex-row();
  @include flex-align-start-center();
  @include flex-layout-gap-row(10px);
}

.gp-build-mobile {
  font-size: 0.85em;
}
