
body {
  margin: 0;
}

.app-container {
  height: 100vh;
  width: 100vw;
}

.portal-content {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.portal-outlet-content {
  max-width: 1100px;
  flex-grow: 1;
}

.portal-outlet-content-mobile {
  padding: 1rem;
}

.pipes-background {
  background-image: url("assets/what-the-hex-opy.png");
}

.height-100-p {
  height: 100%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
