
p.tight {
  margin: 0;
}

.full-width {
  width: 100%;
}

.fx-grow {
  flex: 1 1 100%;
}

@for $i from 0 through 4 {
  $spacing: 0.5;

  .mr-#{$i} {
    margin-right: #{$spacing * $i}rem !important;
  }

  .mt-#{$i} {
    margin-top: #{$spacing * $i}rem !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$spacing * $i}rem !important;
  }

  .my-#{$i} {
    margin-top: #{$spacing * $i}rem !important;
    margin-bottom: #{$spacing * $i}rem !important;
  }

  .m-#{$i} {
    margin: #{$spacing * $i}rem !important;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-500 {
  max-width: 500px;
}

.min-width-500 {
  min-width: 500px;
}

.min-height-500 {
  min-height: 500px;
}


router-outlet + * {
  width: 100%;
}
