
// Import library functions for theme creation.
@import '~@angular/material/theming';
@import "components/alerts";
@import "components/cards";
@import "components/sidebar-section";

// Include non-theme styles for core.
@include mat-core();

// Define your application's custom theme.
$primary: mat-palette($mat-blue-gray, 900);
$accent:  mat-palette($mat-orange, 300, A100, A400);
$theme: mat-light-theme($primary, $accent);

// Include theme styles for Angular Material components.
@include alert-component($theme);
@include card-component($theme);
@include sidebar-section-component($theme);
@include angular-material-theme($theme);

.status-alternative-theme {
  /**$status-primary: mat-palette(#1f78d1, 600);**/
  $status-primary: mat-palette($mat-blue, 600);
  /** $status-primary: #1f78d1; **/
  $status-accent: mat-palette($mat-green, 600);
  $status-warn: mat-palette($mat-red, 700);

  $status-theme: mat-light-theme($status-primary, $status-accent, $status-warn);

  @include angular-material-theme($status-theme);

  $foreground: map-get($status-theme, foreground);

  .mat-stroked-button:not([disabled]).mat-primary {
    border-color: mat-color($status-primary);
  }

  .mat-stroked-button:not([disabled]).mat-accent {
    border-color: mat-color($status-accent);
  }

  .mat-stroked-button:not([disabled]).mat-warn {
    border-color: mat-color($status-warn);
  }

  .mat-stroked-button:not([disabled]) {
    border-color: mat-color($foreground, base)
  }
}
