
.mobile-label {
  display: none;
}

.mobile-table {
  & .mobile-label {
    width: 80px;
    display: inline-block;
    font-weight: bold;
  }

  & .mat-header-row {
    display: none;
  }

  & .mat-cell {
    display: block;
    padding: 8px 5px !important;
    border-bottom: none !important;

    &:last-of-type {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}
